import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Footer from '../Footer/Main'
import { useParams } from 'react-router-dom';
import { getJobDetailsById, getStrapiData } from '../../api.controller';
import { formatDistanceToNow, isBefore, startOfDay } from 'date-fns';


function Main() {

    const [job, setJob] = useState();
    const [duration, setDuration] = useState();
    const [salary, setSalary] = useState();
    const [expired, setExpired] = useState();
    const [data, setData] = useState();

    const { id } = useParams();

    useEffect(() => {
        getJobDetails();
        getStrapiData("job-details-page").then(response => {
            console.log(response);
            setData(response.data);
        });
    }, [])



    const checkIfDateIsBeforeToday = (date) => {
        const today = startOfDay(new Date());
        const givenDate = startOfDay(new Date(date));
        return isBefore(givenDate, today);
    };
    const getJobDetails = async () => {
        try {
            const response = await getJobDetailsById(id);
            const data = response?.data;
            const _duration = formatDistanceToNow(data.createdAt, { addSuffix: true });
            const _salary = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'GHS',
            }).format(data.salary);
            const _expired = checkIfDateIsBeforeToday(data.expiresOn);
            setExpired(_expired);

            setDuration(_duration);
            setSalary(_salary);
            setJob(data);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb data={data} subtitle="Jobs" title="Job Details" />
                <section className="service-one service-one--page">
                    <div className="container">
                        {job && <>
                            {expired && <div style={{ fontSize: 17 }} className="badge bg-danger">No more taking applicants</div>}
                            <div className="d-flex justify-content-between align-items-center">
                                <h1 className="mt-2">{job.jobTitle}</h1>
                                <div>{duration}</div>
                            </div>
                            <div className="d-flex align-items-center mb-4">
                                <img src={`${process.env.REACT_APP_FILE_BASE_URL}/${job.companyLogo}`} style={{ width: 100, background: "#f0f3f9" }} />
                                <h5 className="ms-2">{job.companyName}</h5>
                            </div>

                            <h5>{salary} Per Month</h5>
                            <div className="my-2">
                                {job.jobType ? <span className="badge badge bg-dark me-2">{job.jobType}</span> : ""}
                                {job.companyLocation ? <span className="badge bg-dark me-2">{job.companyLocation}</span> : ""}
                                {job.workingLocationPolicy ? <span className="badge bg-dark me-2">{job.workingLocationPolicy}</span> : ""}
                                {job.minimumExperience ? <span className="badge bg-dark me-2">{job.minimumExperience} Years Experience</span> : ""}
                                {job.minimumEducationQualification ? <span className="badge bg-dark me-2">{job.minimumEducationQualification}</span> : ""}
                            </div>
                            <div>

                            </div>

                            <div className="my-4">{job.quickSummary}</div>
                            <h3 className="mt-5">Responsibilities</h3>
                            <div className="my-4">
                                <ul className="about-one__content__list">
                                    {
                                        job.responsibilities.map(_res => (
                                            <li>
                                                <span className="fa fa-check-circle" />
                                                {_res}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <h3 className="mt-5">Requirements/Qualifications</h3>
                            <div className="my-4">
                                <ul className="about-one__content__list">
                                    {
                                        job.requirementsAndQualifications.map(_req => (
                                            <li>
                                                <span className="fa fa-check-circle" />
                                                {_req}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <h3 className="mt-5">Benefits</h3>
                            <div className="my-4">
                                <ul className="about-one__content__list">
                                    {
                                        job.benefits.map(_req => (
                                            <li>
                                                <span className="fa fa-check-circle" />
                                                {_req}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div style={{ fontSize: 20 }}>To apply, click on this link
                                <div> <a className="fw-bold" href={job.applicationLink}>Apply Here</a></div>
                            </div>
                            {job.email && <div style={{ fontSize: 20 }}>You can send an email to,
                                <div> <a className="fw-bold" href={`mailto:${job.email}`}>{job.email}</a></div>
                            </div>}
                        </>}
                    </div>
                </section>

                <Footer />
            </div>
        </>
    )
}

export default Main